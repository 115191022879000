import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import Efficacite from './pages/Efficacite';

import {useDocTitle} from './components/CustomHook';

import { I18nextProvider } from 'react-i18next';
import Reseau from './pages/Reseau';
import Automatisme from './pages/Automatisme';
import Conception from './pages/Conception';
import Ingenierie from './pages/Ingenierie';
import LignesDeProd from './pages/LignesDeProd';


function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Fame Technoloy | Génie Eléctrique & Procédé Industriels");

  return (
    <>

      <Router>
       
          <Routes>
            <Route path="/" element={<Home />} />
            
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
            <Route path="/efficacite" element={<Efficacite />} />
            <Route path="/conception" element={<Conception />} /> 
            <Route path="/ingenierie" element={<Ingenierie />} /> 
            <Route path="/automatisme" element={<Automatisme />} /> 
            <Route path="/reseau" element={<Reseau />} /> 
            <Route path="/lignesDeProd" element={<LignesDeProd />} /> 
            
          </Routes>
        
      </Router>
    </>
  );
}


export default App;
