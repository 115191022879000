import React,{ useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';

import hero from '../images/blue1.png';
import "../index.css"
import { useTranslation } from 'react-i18next';
function Hero() {
    // Initialize the useTranslation hook
    const { t } = useTranslation();
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}
      useEffect(() => {
        const updateDimension = () => {
          let dim = {...getCurrentDimension()}
          if (dim.width > 900){
            dim.width = 900
          }
          setScreenSize(dim)
          }
          window.addEventListener('resize', updateDimension);
  
  
          return(() => {
              window.removeEventListener('resize', updateDimension);
          })
      }, [screenSize]);
      
      return (
        <>
            
                <div className="mb-20">
                    <NavBar />
                </div>
                
                <div className="m-auto mt-8 lg:mt-4 p-2 md:p-12 h-5/6" >

                    <div className="flex flex-col lg:flex-row py-8 justify-between text-center justify-center lg:text-left">
                        <div className="lg:w-1/3 flex flex-col justify-center"  data-aos-delay="200">
                            
                            <p className="font-serif mb-5 md:text-5xl text-3xl font-bold text-blue-900"> Fame Technology </p> 
                            <p className="text-xl font-serif font-semibold tracking-tight mb-5 text-gray-500 text-justify">est une entreprise de premier plan dans le secteur d’Automatisme Industriel, 
                                des Réseaux Electriques BT et Haute Tension HTA, Tableau électrique , efficacité  énergétique ainsi que le transfert et la mise en place des lignes de production.                                                                                                                                                                                                                                               
                                <br/><br/>Fondé en 2005 notre entreprise a bâti une réputation solide grâce à notre dévouement à la progression technologique et à la pérennité environnementale.
                                Fame Technology vous accompagne dans vos projets en vous proposant des solutions à la réponse de vos attentes en matière de technologie et d’innovation.
                                Une collaboration avec vous pour optimiser vos opérations industrielles, améliorer votre efficacité énergétique et garantir la fiabilité de vos installations électriques.</p>
                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8" id="test" >
                                <Link to="/contact" className="font-serif text-white bg-blue-800 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"   >
                                    Plus d'informations
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link>
                                {/* <Link to="/contact" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Get Started
                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </Link> */}
                                {/* <a href="#_" className="inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg text-white bg-gray-500 hover:bg-gray-400 shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Learn More
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path></svg>
                                </a> */}
                            </div>
                        </div>
                        <div className="flex w-full lg:w-2/3 mx-auto my-15 sm:justify-center flex-col p-4" >
                            <img alt="card img" className="rounded-t duration-1000 mx-auto " src={hero}  style={{ width: `${screenSize.width - 50}px`, height: "600px" }} />
                            <p className="font-serif mt-3 md:text-5xl text-3xl font-bold text-blue-900  text-center" style={{alignSelf:"center"}}>Votre partenaire vers l'excellence !</p>
                        </div>
                    </div>
                </div>
            
        </>
    )
}

export default Hero;