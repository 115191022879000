import React from 'react';
import img from '../images/auto.jpg'; 
import img2 from '../images/ing.jpeg';
import img3 from '../images/tableau.png';
import img4 from '../images/eff.jpg';
import img5 from '../images/test.jpg';
import img6 from '../images/prod.jpg';

import { Link } from 'react-router-dom';
const Services = () => {

    return (
     <div id='services' >   
        <div  className="bg-gray-100 py-20 mt-10 " >
            <section >
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">SECTEURS D'ACTIVITÉS</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        
                    </div>

                    <div className="px-12" >
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <Link to="/reseau">
                                <div className="m-2 text-justify text-sm flex flex-col items-center justify-center">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-2/3 " style={{ width: "500px", height: "200px" }} src={img5} 
                                />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Réseau éléctrique</h2>
                                    <p className="text-md font-medium text-justify">
                                    FAME Technology est une société qui commercialise des solutions destinées au distribution du réseau électrique public et privé, 
                                    comptant sur sa longue expérience et en s'appuyant sur son savoir faire pour offrir à ses clients des solutions adaptées à leur réel besoin.
                                    </p>
                                </div>
                                
                            </Link>
                            </div>
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <Link to="/conception">
                                <div className="m-2 text-justify text-sm flex flex-col items-center justify-center ">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-2/3" style={{ width: "500px", height: "200px" }} src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Conception des tableaux éléctriques</h2>
                                    <p className="text-md font-medium text-justify">
                                    Fame Technology dispose d’un atelier de conception, fabrication et câblage des tableaux électriques . Notre objectif c’est d’Offrir à
                                     nos client des solutions pertinentes avec un haut niveau de conception et leur soumettre une bonne assistance commerciale et un suivi technique performant.

                                    </p>
                                </div>
                                </Link>
                            </div>
                            
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <Link to="/ingenierie">
                                <div className="m-2 text-justify text-sm flex flex-col items-center justify-center h-full">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-full" style={{ width: "500px", height: "200px" }} src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Ingénierie et étude </h2>
                                    <p className="text-md font-medium text-justify"> Fame Technology met à la disposition de ses clients un service d'ingénierie et mobilise un ensemble de compétences 
                                    confirmées pour étudier, planifier , suivre et développer vos projets . Dans l'ultime objectif est la réussite et l’accomplissement optimal de vos projets.
                                    </p>
                                </div>
                                </Link>
                            </div>
                            
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <Link to="/automatisme">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" style={{ width: "500px", height: "200px" }} src={img} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">Automatisme des processus industrielles</h2>
                                    <p className="text-md font-medium text-justify">
                                    Fame Technology s’engage dans la démarche de mise en place des solutions automatisées innovantes adaptées à vos process 
                                    industriel en termes de flexibilité à la conduite et à la pointe de la technologie et aux besoins des entreprises en termes de management.
                                    </p>
                                </div>
                                </Link>
                            </div> 
                            
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <Link to="/efficacite">
                                <div className="m-2 text-justify text-sm flex flex-col items-center justify-center ">
                               
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-full " style={{ width: "500px", height: "200px" }} src={img4}    /> 
                                
                                
                                    <h2 className="font-semibold my-4 text-2xl text-center">Efficacité Énergétique</h2>
                                    <p className="text-md font-medium text-justify">
                                    Fame technology a su développer durant ses 17 années d'existence une expertise dans le domaine de la maîtrise des 
                                    consommation d'énergie et l'implémentation des solutions d’efficacité énergétique afin d’améliorer la sécurité énergétique 
                                    et faire face à la transition énergétique et écologique.
                                    </p>
                                </div>
                                </Link>
                            </div>
                            
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <Link to="/lignesDeProd">
                                <div className="m-2 text-justify text-sm flex flex-col items-center justify-center ">
                               
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out h-full " style={{ width: "500px", height: "200px" }} src={img6}    /> 
                                
                                
                                    <h2 className="font-semibold my-4 text-2xl text-center">Mise En Place Des Lignes De Production</h2>
                                    <p className="text-md font-medium text-justify">
                                    Fame technology vous offre des prestations spécifiques relatives au déplacement transfert et mise en place
                                    de vos lignes de production 
                                    </p>
                                </div>
                                </Link>
                            </div>
                            
                        </div>
                    </div>
            </section>

           
        </div>
        </div>
        
    )
}

export default Services;