import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import ScrollToTop from '../components/ScrollToTop';
import img1 from '../images/reseau1.jpg'; 
import img2 from '../images/reseau2.jpeg'; 
import img3 from '../images/reseau3.jpeg'; 
import img4 from '../images/ONE.jpg';
import img5 from '../images/adm.jpg';
import img6 from '../images/lydec.jpg'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


function Reseau() {
    // Initialize the useTranslation hook
   
      return (
        <>
            <ScrollToTop />
            <div className="mb-20">
              <NavBar />
            </div>  
                
            <div className="efficacite m-auto overflow-hidden  lg:mt-4 p-2 md:p-12" id="efficacite">
                <div className="flex flex-col lg:flex-row  justify-between  lg:text-left ">
                  <div className="lg:w-1/3 flex flex-col justify-center"  data-aos-delay="200">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900"> Réseau éléctrique</h1>
                    <p className="text-md font-medium text-justify">
                        FAME Technology est une société qui commercialise des solutions destinées au distribution
                        du réseau électrique public et privé, comptant sur sa longue expérience et en s'appuyant sur
                        son savoir faire pour offrir à ses clients des solutions adaptées à leur réel besoin .<br/>
                        Fame technology met à la disposition de ses différents clients une offre des prestations
                        technique larges et variés, de la conception jusqu'à la mise en service pour une finalité qui
                        est la concrétisation de vos attentes. 
                        
                    </p>
                    <div className='grid grid-cols-2  mt-10 justify-center'>
                      <div>
                        <img src={img4} alt="ONE Logo" style={{ width: "200px", height: "107px" }} />
                      </div>
                      <div style={{ height: "107px" }} className='justify-center'>
                      <p className='font-bold text-justify  justify-center mt-9'>
                        Cértificat d’Agrément HTA-BT de L'ONE
                      </p>
                      </div>    
                    </div>
                    <div className='grid grid-cols-2  mt-5 '>
                      <div>
                        <img src={img6} alt="lydec Logo" style={{ width: "200px", height: "107px" }} />
                      </div>
                      <p className='font-bold text-justify  justify-center mt-9'>
                        Cértificat d’Agrément HTA-BT Lydec
                      </p>
                          
                    </div>
                    <div className='grid grid-cols-2 mb-10 mt-5'>
                      <div>
                        <img src={img5} alt="lydec Logo" style={{ width: "200px", height: "107px" }} />
                      </div>
                      <p className='font-bold    mt-9'>
                        Cértification De Qualification Et <br/>De Classification
                      </p>
                          
                    </div>

                  </div>
                  
                  <div className="lg:w-2/3 ">
                    <Carousel autoPlay showThumbs={false} infiniteLoop>
                    <div  >
                                
                        <img src={img3} alt="Image 1"style={{ width: "900px", height: "500px" }} />
                      </div>
                      <div className="w-full " >
                                
                        <img src={img2} alt="Image 2" style={{ width: "900px", height: "500px" }}/>
                      </div>
                      <div className="w-full " >
                                
                        <img src={img1} alt="Image 3" style={{ width: "900px", height: "500px" }} />
                      </div>
                    </Carousel>
                  </div>


                </div>    
                </div>  
        
            <Footer />
        </>
    )
}

export default Reseau;

