import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img3 from '../images/tableau1.jpeg'; 

import img1 from '../images/tableau2.png'; 
import img2 from '../images/tableau2.jpeg'; 
import img4 from '../images/reseau3.jpeg'; 
 
import ScrollToTop from '../components/ScrollToTop';

function Conception() {
    // Initialize the useTranslation hook
   
      return (
        <>
        <ScrollToTop />
            <div className="mb-20">
              <NavBar />
            </div>  
                
            <div className="efficacite m-auto overflow-hidden  lg:mt-4 p-2 md:p-12" id="efficacite">
                <div className="flex flex-col lg:flex-row  justify-between  lg:text-left ">
                  <div className="lg:w-1/3 flex flex-col justify-center"  data-aos-delay="200">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900"> Conception des tableaux éléctriques</h1>
                    <p className="text-md font-medium text-justify">
                    Fame Technology dispose d’un atelier de conception, fabrication et câblage des tableaux électriques .
                    Notre objectif c’est d’Offrir à nos client des solutions pertinentes avec un haut niveau de
                    conception et leur soumettre une bonne assistance commerciale et un suivi technique
                    performant.<br/>
                   
                    Notre offre de tableau électrique assure en terme de conception la sûreté , la
                    sécurité la maintenabilité et la qualité .<br/>
                    Nos tableaux électriques répondent aux normes IEC 61439 et réglementations en vigueur et ils
                    sont réalisés suivant les règles de l’art par des équipes de techniciens tableautiers
                    hautement qualifiés .

                      </p>
                  </div>
                  
                  <div className="lg:w-2/3 ">
                    <Carousel autoPlay showThumbs={false} infiniteLoop>
                    <div  >
                                
                        <img src={img3} alt="Image 1" style={{ width: "900px", height: "550px" }}  />
                      </div>
                      <div  >
                                
                        <img src={img2} alt="Image 2" style={{ width: "900px", height: "550px" }} />
                      </div>
                      <div  >
                                
                        <img src={img1} alt="Image 3" style={{ width: "900px", height: "550px" }} />
                      </div>
                    </Carousel>
                  </div>


                </div>    
                </div>  
        
            <Footer />
        </>
    )
}

export default Conception;