import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from '../Navbar/NavLinks';
import logo from '../../images/logoo.jpeg';
import logoleft from '../../images/Frame 3.5.png'
const NavBar = () => {
    const [top, setTop] = useState(true);
    const [isOpen, setisOpen] = React.useState(false);
    function handleClick() {
        setisOpen(!isOpen);
    }
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}

    useEffect(() => {
      const scrollHandler = () => {
        window.pageYOffset > 10 ? setTop(false) : setTop(true)
      };
      window.addEventListener('scroll', scrollHandler);
      const updateDimension = () => {
        console.log(getCurrentDimension())
        setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);


        return(() => {
            window.removeEventListener('scroll', scrollHandler);
            window.removeEventListener('resize', updateDimension);
        })
    }, [top,screenSize]);

    return (
        <nav className={`fixed top-0 z-30 transition duration-300 ease-in-out mb-16 ${!top && 'bg-white shadow-lg'}`} style={{width:`${screenSize.width}px`}}>
            <div className="flex flex-row justify-between items-center py-2">
                <div className="flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold">
                    <Link to="/">
                    <div className='flex flex-row justify-center items-center'>
                    <img src={logo} alt="Fame Technology Logo" className="h-10 md:h-12 lg:h-20 ml-3" />
                    <img src={logoleft} alt="Fame Technology Name" className="h-10 md:h-12 lg:h-10 ml-5"/>
                    </div>
                    </Link>
                </div>
                <div className="group flex flex-col items-center" >
                    <button className="p-2 rounded-lg lg:hidden text-blue-900" onClick={handleClick} >
                        <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {isOpen && (
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                            )}
                            {!isOpen && (
                            <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                            )}
                        </svg>
                    </button>
                    <div className='hidden space-x-6 lg:inline-block p-5' >
                        <NavLinks />
                    </div>

                    <div className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-24 bg-white rounded-lg block lg:hidden shadow-xl top-14 ${  isOpen ? "block" : "hidden" } `}>
                        <div className='flex flex-col space-y-6'>
                            <NavLinks />
                        </div>                                                
                    </div>

                </div>
            </div>
        </nav>
    )
    
}


export default NavBar;
