import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import img1 from '../images/ligne1.jpg'; 
import img2 from '../images/ligne2.jpg';
import img3 from '../images/line.png';
 
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ScrollToTop from '../components/ScrollToTop';


function LignesDeProd() {
    // Initialize the useTranslation hook
   
      return (
        <>
        <ScrollToTop />
            <div className="mb-20">
              <NavBar />
            </div>  
                
            <div className="efficacite m-auto overflow-hidden  lg:mt-4 p-2 md:p-12" id="efficacite">
                <div className="flex flex-col lg:flex-row  justify-between  lg:text-left ">
                  <div className="lg:w-1/3 flex flex-col justify-center"  data-aos-delay="200">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900"> Mise En Place Des Lignes De Production</h1>
                    <p className="text-md font-medium text-justify">
                    Notre mission est de vous offrir des prestations spécifiques relatives au déplacement transfert et mise en place
                    de vos lignes de production.<br/>
                    Une équipe de professionnels, comprenant des ingénieurs, des gestionnaires de projet, des techniciens et des
                    spécialistes en logistique, est généralement chargée de planifier et d'exécuter le transfert et la mise en place. Ils
                    évaluent les défis potentiels et développent un plan détaillé.

                      </p>
                  </div>
                  
                  <div className="lg:w-2/3 ">
                    <Carousel autoPlay showThumbs={false} infiniteLoop>
                    <div className="w-full  justify-center" >
                                
                        <img src={img1} alt="Image 1" style={{ width: "900px", height: "500px" }}  />
                      </div>
                      <div className="w-full " >
                                
                        <img src={img3} alt="Image 3" style={{ width: "900px", height: "500px" }}  />
                      </div>
                    </Carousel>
                  </div>


                </div>    
                </div>  
        
            <Footer />
        </>
    )
}

export default LignesDeProd;