import React,{ useState, useEffect } from 'react';
import log1 from '../images/clients/1.png';
import log3 from '../images/clients/3.png';
import log4 from '../images/clients/4.png';
import log5 from '../images/clients/5.png';
import log6 from '../images/clients/6.jpg';
import log7 from '../images/clients/7.png';
import log8 from '../images/clients/8.png';
import log9 from '../images/clients/9.png';
import log10 from '../images/clients/10.png';
import log11 from '../images/clients/11.jpg';
import log12 from '../images/clients/12.png';
import log13 from '../images/clients/13.jpg';
import log14 from '../images/clients/14.jpg';
import log15 from '../images/clients/15.png';
import log16 from '../images/clients/16.png';
import log17 from '../images/clients/17.jpeg';
import log18 from '../images/clients/18.png';
import log19 from '../images/clients/19.png';
import log20 from '../images/clients/20.png';
import log21 from '../images/clients/21.png';
import log22 from '../images/clients/22.png';
import log23 from '../images/clients/23.jpeg';
import log24 from '../images/clients/24.png';
import log25 from '../images/clients/25.jpeg';
import log26 from '../images/clients/26.jpeg';
import log27 from '../images/clients/27.jpeg';

import { Carousel } from 'flowbite-react';

const clientImage = {
    height: '10rem',
    mixBlendMode: 'colorBurn'
}
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const Clients = () => {
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    function getCurrentDimension(){
    	return {
      		width: window.innerWidth,
      		height: window.innerHeight
    	}
  	}
  
  	useEffect(() => {
    		const updateDimension = () => {
      			setScreenSize(getCurrentDimension())
    		}
    		window.addEventListener('resize', updateDimension);
    
		
    		return(() => {
        		window.removeEventListener('resize', updateDimension);
    		})
  	}, [screenSize])
    
    function ub(arr, k) {
    
        let res = [];
    
      for (let i = 0; i < arr.length; i += k) res.push(arr.slice(i, i + k));
    
      return res;
    
    }

    

    return (
        <div id='references' >  
        <div className="mt-20  bg-gray-200 ">
            <section >
                <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Nos Clients</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        
                    </div>
                    {(screenSize.width > 640)?<>
                        <Carousel style={{height:"25rem"}}>
                        <div className="grid sm:grid-cols-4 lg:grid-cols-4">
                            <div style={clientImage} className= " flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                <img src={log1} alt="client" />                           
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log3} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log4} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log5} alt="client" />                            
                            </div>
                        </div>
                        <div className="grid sm:grid-cols-4 lg:grid-cols-4">
                            <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                <img src={log6} alt="client" />                           
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log7} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log8} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log9} alt="client" />                            
                            </div>
                        </div>
                        <div className="grid sm:grid-cols-4 lg:grid-cols-4">
                            <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                <img src={log10} alt="client" />                           
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log11} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log12} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log13} alt="client" />                            
                            </div>
                        </div>
                        <div className="grid sm:grid-cols-4 lg:grid-cols-4">
                            <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log14} alt="client" />                           
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log15} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log16} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log17} alt="client" />                            
                            </div>
                        </div>
                        <div className="grid sm:grid-cols-4 lg:grid-cols-4">
                            <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                <img src={log18} alt="client" />                           
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log19} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log20} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log21} alt="client" />                            
                            </div>
                        </div>
                        <div className="grid sm:grid-cols-4 lg:grid-cols-4">
                            <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                <img src={log22} alt="client" />                           
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log23} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log24} alt="client" />                            
                            </div>
                            <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log25} alt="client" />                            
                            </div>
                        </div>
                        <div className="grid sm:grid-cols-4 lg:grid-cols-4">
                            
                            <div/>
                            <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                <img src={log26} alt="client" />                           
                            </div>
                            
                            <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                <img src={log27} alt="client" />                           
                            </div>
                            <div/>
                       </div>
                        </Carousel>
                        </>:<>
                        <Carousel style={{height:"25rem",width:`${screenSize.width}px`}}>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className= " flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                    <img src={log1} alt="client" style={{width:`${screenSize.width -50}px`}}/>                           
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log3} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log4} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log5} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                    <img src={log6} alt="client" style={{width:`${screenSize.width-50}px`}} />                           
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log7} alt="client"style={{width:`${screenSize.width-50}px`}} />                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log8} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log9} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log10} alt="client" style={{width:`${screenSize.width-50}px`}}/>                           
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log11} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log12} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log13} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                    <img src={log14} alt="client" style={{width:`${screenSize.width-50}px`}}/>                           
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log15} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log16} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log17} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100 ">
                                    <img src={log18} alt="client" style={{width:`${screenSize.width-50}px`}}/>                           
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log19} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log20} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log21} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log22} alt="client" style={{width:`${screenSize.width-50}px`}}/>                           
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log23} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log24} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log25} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log26} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                            <div className="grid sm:grid-cols-1 lg:grid-cols-1">
                                <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-100">
                                    <img src={log27} alt="client" style={{width:`${screenSize.width-50}px`}}/>                            
                                </div>
                            </div>
                        </Carousel>
                        </>}
                    
                    
                        
                
            </section>
        </div>
        </div>
    )
}

export default Clients;