import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img1 from '../images/automatisme2.jpg'; 
import img2 from '../images/automatisme3.jpg'; 
import img3 from '../images/auto.png'; 

import ScrollToTop from '../components/ScrollToTop';

function Automatisme() {
    // Initialize the useTranslation hook
   
      return (
        <>
           <ScrollToTop />
           <div className="mb-20">
              <NavBar />
            </div>  
                
            <div className="efficacite m-auto overflow-hidden  lg:mt-4 p-2 md:p-12" id="efficacite">
                <div className="flex flex-col lg:flex-row  justify-between  lg:text-left ">
                  <div className="lg:w-1/3 flex flex-col justify-center"  data-aos-delay="200">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900"> Automatisme des processus industrielles</h1>
                    <p className="text-md font-medium text-justify">
                    Fame Technology s’engage dans la démarche de mise en place des solutions automatisées
                    innovantes adaptées à vos process industriel en termes de flexibilité à la conduite et à la
                    pointe de la technologie et aux besoins des entreprises en termes de management .
                    <br/>
                   
                    Le développement de l’industrie 4.0ainsi que l’adoption de l'internet des objet IOT ont
                    incité Fame technology à prendre part de cette évolution en intégrant ses
                    technologies dans nos solutions et de développer en parallèle des applications qui
                    renforcent la sécurité des informations des entreprises.
                      </p>
                  </div>
                  
                  <div className="lg:w-2/3 ">
                    <Carousel autoPlay showThumbs={false} infiniteLoop>
                    <div >
                                
                        <img src={img1} alt="Image 1" style={{ width: "900px", height: "500px" }}  />
                      </div>
                      <div className="w-full" >
                                
                        <img src={img2} alt="Image 2"  style={{ width: "900px", height: "500px" }} />
                      </div>
                      <div  >
                                
                        <img src={img3} alt="Image 3" style={{ width: "900px", height: "500px" }} />
                      </div>
                    </Carousel>
                  </div>


                </div>    
                </div>  
        
            <Footer />


       </>
    )
}

export default Automatisme;