import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import img1 from '../images/etude.jpeg';
import img2 from '../images/etude1.jpeg';
 
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


import ScrollToTop from '../components/ScrollToTop';

function Ingenierie() {
    // Initialize the useTranslation hook
   
      return (
        <>
        <ScrollToTop />
            <div className="mb-20">
              <NavBar />
            </div>  
                
            <div className="efficacite m-auto overflow-hidden  lg:mt-4 p-2 md:p-12" id="efficacite">
                <div className="flex flex-col lg:flex-row  justify-between  lg:text-left ">
                  <div className="lg:w-1/3 flex flex-col justify-center"  data-aos-delay="200">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900"> Ingénierie et étude</h1>
                    <p className="text-md font-medium text-justify">
                        Fame Technology met à la disposition de ses clients un service d'ingénierie et mobilise un
                        ensemble de compétences confirmées pour étudier, planifier , suivre et développer vos
                        projets . Dans l'ultime objectif est la réussite et l’accomplissement optimal de vos projets.
                        <br/>
                        Fame technology dispose d’outils informatique performants ,des instruments de mesure et
                        de contrôle ,des logiciels de calcul de dimensionnement et de dessins afin de vous éditer un
                        livrable de document technique suivant les normes et réglementations en vigueur pour vos
                        projet d’installation électrique<br/> HTA/BT , CFO /CFA , automatisme et efficacité énergétique.

                      </p>
                  </div>
                  
                  <div className="lg:w-2/3 ">
                    <Carousel autoPlay showThumbs={false} infiniteLoop>
                   
                      <div className="w-full " >
                                
                        <img src={img1} alt="Image 2" style={{ width: "900px", height: "500px" }} />
                      </div>
                      <div className="w-full " >
                                
                        <img src={img2} alt="Image 3" style={{ width: "900px", height: "500px" }}  />
                      </div>
                    </Carousel>
                  </div>


                </div>    
                </div>  
        
            <Footer />
        </>
    )
}

export default Ingenierie;