import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#hero">
                À PROPOS DE NOUS
            </HashLink>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#services">
                NOS SERVICES & SOLUTIONS
            </HashLink>
           
           
            
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/contact">
                NOUS CONTACTER
            </HashLink>
           
        </>
    )
}

export default NavLinks;