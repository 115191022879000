import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import img3 from '../images/energie.jpeg'; 
import img1 from '../images/efficacite.jpeg'; 
import img2 from '../images/audit.jpeg'; 

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import ScrollToTop from '../components/ScrollToTop';

function Efficacite() {
    // Initialize the useTranslation hook
   
      return (
        <>
        <ScrollToTop />
            <div className="mb-20">
              <NavBar />
            </div>  
                
            <div className="efficacite m-auto overflow-hidden  lg:mt-4 p-2 md:p-12" id="efficacite">
                <div className="flex flex-col lg:flex-row  justify-between  lg:text-left ">
                  <div className="lg:w-1/3 flex flex-col justify-center"  data-aos-delay="200">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900"> Efficacité Énergétique</h1>
                    <p className="text-md font-medium text-justify">
                    Fame technology a su développer durant ses 17 années d'existence une expertise dans le
                    domaine de la maîtrise des consommation d'énergie et l'implémentation des solutions
                    d’efficacité énergétique afin d’améliorer la sécurité énergétique et faire face à la transition
                    énergétique et écologique.
                    <br/>
                    Fame Technology met à la disposition de ses clients des prestations d'ingénierie qui aide à
                    l'amélioration des performances énergétique et à l’optimisation des consommations .
                      </p>
                  </div>
                  
                  <div className="lg:w-2/3 ">
                    <Carousel autoPlay showThumbs={false} infiniteLoop>
                    <div className="w-full  justify-center" >
                                
                        <img src={img3} alt="Image 1" style={{ width: "900px", height: "500px" }} />
                      </div>
                      <div className="w-full " >
                                
                        <img src={img1} alt="Image 2" style={{ width: "900px", height: "500px" }}/>
                      </div>
                      <div className="w-full " >
                                
                        <img src={img2} alt="Image 3" style={{ width: "900px", height: "500px" }} />
                      </div>
                    </Carousel>
                  </div>


                </div>    
                </div>  
        
            <Footer />
        
        </>
    )
}

export default Efficacite;